//dependencies
// import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';

//components

//css
import "../Footer/Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  const [locale, setLocale] = React.useState<any>(
    localStorage.getItem("i18nextLng")
  );
  const localeData = localStorage.getItem("i18nextLng");

  /**
 * useEffect
 */
  useEffect(() => {
    setLocale(localeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);


  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/company/sone-4-as/", "_blank");
  };

  const handleYouTubeClick = () => {
    window.open("https://www.youtube.com/@sone4as", "_blank");
  };


  return (

    <footer className="footer">
      <div className="container">
        <div className="footer-top-part">
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <h4> <Link href={`https://www.sone4.no/${localeData}/products`}>{t("footer.ourProduct")}</Link></h4>
              <ul>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/products/15`}>{t("footer.softWareAndState")}</Link>
                </li>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/products/18`}>{t("footer.programming")}</Link>
                </li>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/products/19`}>Exact WMS</Link>
                </li>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/products/20`}>{t("footer.Odatio")}</Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12}>
              <h4>{t("footer.sone4")}</h4>
              <ul>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/products`}>{t("footer.ourProduct")}</Link>
                </li>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/news`}>{t("footer.newFromZone4")}</Link>
                </li>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/news/5`}>{t("footer.aboutUs")}</Link>
                </li>
                <li>
                  <Link href={`https://www.sone4.no/${localeData}/contact-us`}>{t("footer.contact")}</Link>
                </li>
              </ul>
            </Grid>
            <Grid className="text-right social-icon" item md={4} xs={12}>
              <IconButton onClick={handleLinkedInClick}>
                <LinkedInIcon />
              </IconButton>
              <IconButton onClick={handleYouTubeClick}>
                <YouTubeIcon />
              </IconButton>


            </Grid>
          </Grid>
        </div>
      </div>
      <div className="footer-bottom-part text-center p-4">© SONE 4</div>
    </footer>
  );
};

export default Footer;
