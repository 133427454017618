/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import ReactPlayer from "react-player";
import {
  replaceUploadPath
} from "../../../src/components/Helper";

//components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import News from "../../interface/interface";

//css
import "./partners.scss";

function PartnersDetails() {
  let data = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [locale, setLocale] = React.useState<any>(localStorage.getItem("i18nextLng"));
  const [isLoading, setLoading] = React.useState(false);
  const [partnerData, setPartnersData] = React.useState<any>([]);
  const [newsData, setNewsData] = React.useState<Array<News>>([]);
  const [filterNews, setFilterNews] = React.useState<Array<News>>([]);

  const localeData = localStorage.getItem("i18nextLng");


  /**
   * useEffect
   */
  useEffect(() => {
    getAllPartners()
    getAllNews()
    setLocale(localeData);
  }, [locale]);

  useEffect(() => {
    setFilterNews(newsData);
  }, [newsData]);

  /**
 * getAllNews Api
 */
  const getAllNews = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}v1/homes?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setNewsData(json.data)
        setLoading(false);
      });
  };

  /**
 * getAllPartners Api
 */
  const getAllPartners = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}v1/partners/${id}?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setPartnersData(json?.data?.attributes);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      {partnerData?.headingImage?.data !== null && <div
        className="headingBanner"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}${partnerData?.headingImage?.data?.[0]?.attributes?.url})`,
        }}
      >
        <div className="container">
          <div className="headingBanner__content">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => {
                  navigate(`/${localeData}`);
                }}
              >
                {t("breadcrumbs.home")}
              </Link>

              <Link
                color="inherit"
                onClick={() => {
                  navigate(`/${localeData}/partners`);
                }}
              >
                {partnerData?.label}
              </Link>
              <Typography color="text.primary">
                {partnerData?.title}
              </Typography>
            </Breadcrumbs>
            <h2 className="headingBanner__title">
              {" "}
              {partnerData?.title}
            </h2>
          </div>
        </div>
      </div>}

      <div className="contentModule">
        <div className="container">
          <div className="contentModule__inner">
            <h3 className="contentModule__title">
              {partnerData?.mainTitle}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  replaceUploadPath(partnerData?.descriptions)
              }}
            ></div>
            <h5 className="contentModule__subTitle">
              {" "}
              {partnerData?.subTitle}
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  replaceUploadPath(partnerData?.subDescription)
              }}
            ></div>
            {partnerData?.subImage?.data !== null && <img
              src={`${process.env.REACT_APP_API_URI}${partnerData?.subImage?.data?.[0]?.attributes?.url}`}
              className="w-100 img-fluid"
              alt=""
            />}
          </div>
        </div>
      </div>

      <div className="newsBlock" style={{ background: "#F4FAFC" }}>
        <div className="container">
          <div className="newsBlock__heading">
            <h6 className="newsBlock__subTitle">{t("home.newsAbout")}</h6>
            <h5 className="newsBlock__title">{t("home.areYouUpdated?")}</h5>
          </div>
          <div className="row" >
            {/* start Map */}
            {
              partnerData?.news?.data?.map((item: any) => {
                return filterNews.filter((itemNews: any) => itemNews?.id === item?.id).map((itemNews: News) => {
                  return (
                    <div className="col-lg-4" onClick={() => {
                      navigate(`/${localeData}/news/${itemNews?.id}`, {
                        state: "",
                      });
                    }}>
                      <div className="newsCard" style={{ background: "#fff" }}>
                        <div className="newsCard__img">
                          <img
                            src={`${process.env.REACT_APP_API_URI}${itemNews?.attributes?.image?.data?.[0]?.attributes?.url}`}
                            alt="newsImage"
                            className="img-fluid"
                          />
                        </div>
                        <div className="newsCard__content">
                          <h5 className="newsCard__title">
                            {itemNews?.attributes?.title}
                          </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                itemNews?.attributes?.descriptions,
                            }}
                          ></p>
                          <div className="newsCard__btn">
                            {itemNews?.attributes?.link}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              })
            }
          </div>
          {/* end Map */}
        </div>
      </div>

      {partnerData?.image?.data !== null && <div className="videoBlock">
        <div className="container">
          <div className="videoBlock__inner">
            <img
              src={`${process.env.REACT_APP_API_URI}${partnerData?.image?.data?.[0]?.attributes?.url}`}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>}

      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}

      {partnerData?.videoLink?.data !== null && <div className="videoBlock" style={{ background: "#fff" }}>
        <div className="container">
          <div className="videoBlock__inner" style={{ maxWidth: "100%" }}>
            <ReactPlayer
              url={`${process.env.REACT_APP_API_URI}${partnerData?.videoLink?.data?.[0]?.attributes?.url}`}
              playing={true}
              controls={true}
              loop={true}
              muted={true}
              playsinline={true}
            />
          </div>
        </div>
      </div>}

      <Footer />
    </div>
  );
}

export default PartnersDetails;
