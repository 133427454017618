/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import {
  replaceUploadPath
} from "../../../src/components/Helper";

//components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import News from "../../interface/interface";

//image

//css
import "./details.scss";

function Details() {
  const { id } = useParams();
  // let id = detailId?.split("-")[1];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const [locale, setLocale] = React.useState<any>(localStorage.getItem("i18nextLng"));
  const [newsData, setNewsData] = React.useState<Array<News>>([]);
  const [filterNews, setFilterNews] = React.useState<Array<News>>([]);
  const [product, setProduct] = React.useState<any>([]);
  const localeData = localStorage.getItem("i18nextLng");

  /**
   * useEffect
   */
  useEffect(() => {
    getAllProduct()
    getAllNews();
    setLocale(localeData);
  }, [locale]);

  useEffect(() => {
    setFilterNews(newsData);
  }, [newsData]);

  /**
   * getAllNews Api
   */
  const getAllNews = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}v1/homes?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setNewsData(json.data)
        setLoading(false);
      });
  };


  /**
   * getAllProduct Api
   */
  const getAllProduct = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}v1/products/${id}?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setProduct(json.data.attributes);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      {product?.headingImage?.data !== null && <div
        className="headingBanner"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}${product?.headingImage?.data?.[0]?.attributes?.url})`,
        }}
      >
        <div className="container">
          <div className="headingBanner__content">
            <Breadcrumbs aria-label="breadcrumb">

              <Link
                color="inherit"
                onClick={() => {
                  navigate(`/${localeData}`);
                }}
              >
                {t("breadcrumbs.home")}
              </Link>

              <Link
                color="inherit"
                onClick={() => {
                  navigate(`/${localeData}/products`);
                }}
              >
                {product?.label}
              </Link>
              <Typography color="text.primary">
                {product?.title}
              </Typography>
            </Breadcrumbs>
            <h2 className="headingBanner__title">
              {" "}
              {product?.title}
            </h2>
          </div>
        </div>
      </div>}

      <div className="contentModule">
        <div className="container">
          <div className="contentModule__inner">
            <h3 className="contentModule__title">
              {product?.mainTitle}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  replaceUploadPath(product?.descriptions),
              }}
            ></div>
            <h5 className="contentModule__subTitle">
              {" "}
              {product?.subTitle}
            </h5>
            {/* <p>{product?.subDescription}</p> */}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  replaceUploadPath(product?.subDescription),
              }}
            ></div>

            {product?.subImage?.data !== null && <img
              src={`${process.env.REACT_APP_API_URI}${product?.subImage?.data?.[0]?.attributes?.url}`}
              className="w-100 img-fluid"
              alt=""
            />}
          </div>
        </div>
      </div>

      <div className="newsBlock" style={{ background: "#F4FAFC" }}>
        <div className="container">
          <div className="newsBlock__heading">
            <h6 className="newsBlock__subTitle">{t("home.newsAbout")}</h6>
            <h5 className="newsBlock__title">{t("home.areYouUpdated?")}</h5>
          </div>
          <div className="row">
            {/* start Map */}
            {product?.news?.data?.map((item: any) => {
              return filterNews.filter((itemNews: any) => itemNews?.id === item?.id).map((itemNews: News) => {
                return (
                  <div className="col-lg-4" onClick={() => {
                    navigate(`/${localeData}/news/${itemNews?.id}`, {
                      state: "",
                    });
                  }}>
                    <div className="newsCard" style={{ background: "#fff" }}>
                      <div className="newsCard__img">
                        <img
                          src={`${process.env.REACT_APP_API_URI}${itemNews?.attributes?.image?.data?.[0].attributes?.url}`}
                          alt="newsImage"
                          className="img-fluid"
                        />
                      </div>
                      <div className="newsCard__content">
                        <h5 className="newsCard__title">
                          {itemNews?.attributes?.title}
                        </h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              replaceUploadPath(itemNews?.attributes?.descriptions),
                          }}
                        ></div>
                        <div className="newsCard__btn">
                          {itemNews?.attributes?.link}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            })
            }
            {/* end Map */}
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}

      {product?.image?.data !== null && <div className="videoBlock">
        <div className="container">
          <div className="videoBlock__inner">
            <img
              src={`${process.env.REACT_APP_API_URI}${product?.image?.data?.attributes?.url}`}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>}

      {product?.videoLink?.data !== null && <div className="videoBlock" style={{ background: "#fff" }}>
        <div className="container">
          <div className="videoBlock__inner" style={{ maxWidth: "100%" }}>
            <ReactPlayer
              url={`${process.env.REACT_APP_API_URI}${product?.videoLink?.data?.[0]?.attributes?.url}`}
              playing={true}
              controls={true}
              loop={true}
              muted={true}
              playsinline={true}
            />
          </div>
        </div>
      </div>}
      <Footer />
    </div>
  );
}

export default Details;
