//dependencies
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Button, Container, Box } from "@mui/material";

//image
import { ReactComponent as Email } from "../../assets/images/email.svg";

function ThankYou() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [locale, setLocale] = React.useState<any>(
    localStorage.getItem("i18nextLng")
  );

  const localeData = localStorage.getItem("i18nextLng");

  /**
   * useEffect
   */
  useEffect(() => {
    setLocale(localeData);
  }, [locale]);

  return (
    <div>
      <Header />
      <Container>
        <Box className="thankYouPage">
          <Email height={"100px"} />
          <h1>{t("contact.ThanksForSubmitting")}</h1>
          <p>{t("contact.yourMessageSent!")}</p>
          <Button
            onClick={() => {
              navigate(`/${localeData}`);
            }}
          >
            {t("contact.goHome")}
          </Button>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default ThankYou;
