
// // Usage example
// const htmlString = '<html><body><img alt="odatio_web.png" src="/uploads/odatio_web_bef31098b5.png" srcset="/uploads/thumbnail_odatio_web_bef31098b5.png 218w" sizes="100vw" width="218"></body></html>';
// const modifiedHtmlString = replaceUploadPath(htmlString);
// console.log(modifiedHtmlString);

// export const replaceUploadPath = (htmlString: string): string => {
//     debugger
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlString, 'text/html');

//     const imgTags = doc.getElementsByTagName('img');
//     for (let i = 0; i < imgTags.length; i++) {
//         const imgTag = imgTags[i];
//         const src = imgTag.getAttribute('src');
//         if (src) {
//             const modifiedSrc = src.replace('/uploads/', 'https://api.test.sone4.no/uploads/')
//                 .replace('/uploads/thumbnail_', '/uploads/thumbnail_')
//                 .replace('/uploads/small_', '/uploads/small_');
//             imgTag.setAttribute('src', modifiedSrc);
//         }

//         const srcset = imgTag.getAttribute('srcset');
//         if (srcset) {
//             debugger
//             const modifiedSrcset = srcset.replace('/uploads/', 'https://api.test.sone4.no/uploads/')
//                 .replace('/uploads/thumbnail_', '/uploads/thumbnail_')
//                 .replace('/uploads/small_', '/uploads/small_');
//             imgTag.setAttribute('srcset', modifiedSrcset);
//         }
//     }

//     const modifiedHtmlString = doc.documentElement.outerHTML;
//     return modifiedHtmlString;
// }

// // Usage example
// const htmlString = '<html><body><img alt="odatio_web.png" src="/uploads/odatio_web_bef31098b5.png" srcset="/uploads/thumbnail_odatio_web_bef31098b5.png 218w" sizes="100vw" width="218"></body></html>';
// const modifiedHtmlString = replaceUploadPath(htmlString);
// console.log(modifiedHtmlString);


export const replaceUploadPath = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const imgTags = doc.getElementsByTagName('img');
    for (let i = 0; i < imgTags.length; i++) {
        const imgTag = imgTags[i];

        // Replace 'src' attribute
        const src = imgTag.getAttribute('src');
        if (src) {
            const modifiedSrc = src.replace('/uploads/', `${process.env.REACT_APP_API_URI}uploads/`);
            imgTag.setAttribute('src', modifiedSrc);
        }

        // Replace 'srcset' attribute
        const srcset = imgTag.getAttribute('srcset');
        if (srcset) {
            const modifiedSrcset = srcset.replace('/uploads/', `${process.env.REACT_APP_API_URI}uploads/`)
                .replace('/uploads/thumbnail_', '/uploads/thumbnail_')
                .replace('/uploads/small_', `${process.env.REACT_APP_API_URI}uploads/small_`);
            imgTag.setAttribute('srcset', modifiedSrcset);
        }
    }

    const modifiedHtmlString = doc.documentElement.outerHTML;
    return modifiedHtmlString;
}



