//dependencies
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import { useLocation } from 'react-router-dom';
import i18n from "../src/components/Language/i18n";
import { I18nextProvider } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import TagManager from 'react-gtm-module'


//components
import Home from "./components/Home/Home";
import Product from "./components/Product/Product";
import Partners from "./components/Partners/Partners";
import News from "./components/News/News";
import ContactUs from "./components/ContactUs/ContactUs";
import Details from "./components/Product/Details";
import PartnersDetails from "./components/Partners/PartnersDetails";
import NewsDetails from "./components/News/NewsDetails";
import MetaData from "./MetaData";
import ThankYou from "./components/ContactUs/ThankYou";

//css
import "./App.scss";


function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;

    if (url === 'http://localhost:3000/') {
      navigate('/nb-NO');
    }
    else if (url === 'https://sone4.test.sone4.no/') {
      navigate('/nb-NO');
    }
    else if (url === 'http://localhost:3000/en-US') {
      navigate('/nb-NO');
    }
    else if (url === 'https://sone4.test.sone4.no/en-US') {
      navigate('/nb-NO');
    }
    else if (url === 'http://localhost:3000/en-GB') {
      navigate('/nb-NO');
    }
    else if (url === 'https://sone4.test.sone4.no/en-GB') {
      navigate('/nb-NO');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Google Analytics Code
   */
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-91R3TCZV50");
    // Track pageview when component is mounted
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  //   useEffect(() => {
  //     ReactGA.initialize('GTM-KXWZ3XT');
  //  }, [])

  const tagManagerArgs = {
    gtmId: 'GTM-KXWZ3XT'
  }

  TagManager.initialize(tagManagerArgs)


  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [cookiesAccepted, setCookiesAccepted] = React.useState(false);

  const handleAllowAllCookies = () => {
    // Logic for allowing all cookies
    setCookiesAccepted(true);
  };

  const handleDeclineCookies = () => {
    // Logic for declining cookies
    setCookiesAccepted(false);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path={`/:localeData`} element={<Home />} />
        <Route path={`/:localeData/products`} element={<Product />} />
        <Route path={`/:localeData/products/:id`} element={<Details />} />
        <Route path={`/:localeData/news`} element={<News />} />
        <Route path={`/:localeData/news/:id`} element={<NewsDetails />} />
        <Route path={`/:localeData/partners`} element={<Partners />} />
        <Route path={`/:localeData/partners/:id`} element={<PartnersDetails />} />
        <Route path={"/:localeData/contact-us"} element={<ContactUs />} />
        <Route path={"/:localeData/thankYou"} element={<ThankYou />} />
      </Routes>

      {!cookiesAccepted && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="myCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          onAccept={handleAllowAllCookies}
          onDecline={handleDeclineCookies}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      )}
      <MetaData />
    </I18nextProvider >

  );
}

export default App;
