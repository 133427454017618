/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import i18n from "../Language/i18n";
import Internationalization from "../../interface/interface";


type language = {
  code: string;
  dispName: string;
};
const langs: language[] = [
  {
    code: "nb-NO",
    dispName: "Norwegian",
  },
  {
    code: "en",
    dispName: "English",
  },
  {
    code: "sv-SE",
    dispName: "Swedish",
  },
  {
    code: "da",
    dispName: "Danish",
  },
];

export default function SplitButton(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [languageData, setLanguageData] = React.useState<
    Array<Internationalization>
  >([]);

  const [locale, setLocale] = React.useState<any>(localStorage.getItem("i18nextLng"));
  const localeData = localStorage.getItem("i18nextLng")

  let selectedLanguage = localStorage.getItem("i18nextLng")

  /**
   * Not Use
   */
  const getAllInternationalizationData = () => {
    fetch(`${process.env.REACT_APP_API_URI}v1/i18n/locales`)
      .then((response) => response.json())
      .then((json) => {
        setLanguageData(json);
      });
  };

  useEffect(() => {
    // Check if selected language is "en-US", "en-GB", or "en-CA" and replace it with "nb-NO" in localStorage
    if (selectedLanguage === "en-US" || selectedLanguage === "en-GB" || selectedLanguage === "en-CA") {
      localStorage.setItem("i18nextLng", "nb-NO");
    }
  }, []);



  /**
 * useEffect
 */
  useEffect(() => {
    setLocale(localeData);
  }, [locale]);

  /**
   * URL Dynamic http://localhost:3000/new-data/products
   */
  useEffect(() => {
    const url = window.location.href;
    const langs = ["en-US", "en-GB", "en-CA", "sv-SE", "da", "nb-NO", "en",]; // Example array of language codes 
    const currentLang = langs.find(lang => url.includes(lang));
    const dynamicData = `/${localeData}`; // Example dynamic data to append
    const updatedUrl = url.replace(`/${String(currentLang)}`, String(dynamicData));
    if (updatedUrl !== url) {
      window.location.href = updatedUrl;
    }
  }, []);

  React.useEffect(() => {
    getAllInternationalizationData();
    let findIndex = langs.findIndex(
      (option) => option.code === selectedLanguage
    );
    setSelectedIndex(findIndex);
  }, []);

  function handleMenuItemClick(
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) {
    setSelectedIndex(index);
    setOpen(false);
    i18n.changeLanguage(langs[index].code);
    window.location.reload();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  //function ShowFlag({ lang }: { lang: language; }) {
  const ShowFlag = (lang: language, inclName: boolean) => {
    if (lang !== undefined) {
      if (lang.code === "en") {
        document.title = "Sone 4 AS - Norwegian logistic tech company";
        if (inclName) {
          return (
            <>
              <span className="fi fi-gb"></span>{" "}
              <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
            </>
          );
        } else {
          return <span className="fi fi-gb"></span>;
        }
      } else if (lang.code === "nb-NO") {
        document.title = "Sone 4 AS - Norsk logistikk teknologiselskap";
        if (inclName) {
          return (
            <>
              <span className="fi fi-no"></span>
              <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
            </>
          );
        } else {
          return <span className="fi fi-no"></span>;
        }
        // } else if (lang.code === "no") {
        //   if (inclName) {
        //     return (
        //       <>
        //         <span className="fi fi-no"></span>
        //         <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
        //       </>
        //     );
        //   } else {
        //     return <span className="fi fi-no"></span>;
        //   }
      } else if (lang.code === "sv-SE") {
        document.title = "Sone 4 AS - norskt logistikföretag";
        if (inclName) {
          return (
            <>
              <span className="fi fi-se"></span>
              <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
            </>
          );
        } else {
          return <span className="fi fi-se"></span>;
        }
      } else if (lang.code === "da") {
        document.title = "Sone 4 AS - Norsk logistisk teknologivirksomhed";
        if (inclName) {
          return (
            <>
              <span className="fi fi-dk"></span>
              <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
            </>
          );
        } else {
          return <span className="fi fi-dk"></span>;
        }
      } else {
        throw Error("Unsupported language: " + lang.code);
      }
    }
  };

  return (
    // <>
    //   <link
    //     rel="stylesheet"
    //     href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
    //   />

    //   <ButtonGroup
    //     variant="contained"
    //     ref={anchorRef}
    //     aria-label="split button"
    //   >
    //     <Button
    //       size="small"
    //       aria-controls={open ? "split-button-menu" : undefined}
    //       aria-expanded={open ? "true" : undefined}
    //       aria-label="select merge strategy"
    //       aria-haspopup="menu"
    //       onClick={handleToggle}
    //     >
    //       {ShowFlag(langs[selectedIndex], false)}
    //       <ArrowDropDownIcon />
    //     </Button>
    //   </ButtonGroup>
    //   <Popper
    //     sx={{
    //       zIndex: 1,
    //     }}
    //     open={open}
    //     anchorEl={anchorRef.current}
    //     role={undefined}
    //     transition
    //     disablePortal
    //   >
    //     {({ TransitionProps, placement }) => (
    //       <Grow
    //         {...TransitionProps}
    //         style={{
    //           transformOrigin:
    //             placement === "bottom" ? "center top" : "center bottom",
    //         }}
    //       >
    //         <Paper>
    //           <ClickAwayListener onClickAway={handleClose}>
    //             <MenuList id="split-button-menu" autoFocusItem>
    //               {languageData.map(
    //                 (lang: Internationalization, index: number) => {
    //                   return (
    //                     <div>
    //                       <MenuItem
    //                         key={lang.code}
    //                         selected={index === selectedIndex}
    //                         onClick={(event) =>
    //                           handleMenuItemClick(event, index)
    //                         }
    //                         // disabled={index === 2}
    //                       >
    //                         {ShowFlag(lang, true)}
    //                       </MenuItem>
    //                     </div>
    //                   );
    //                 }
    //               )}
    //             </MenuList>
    //           </ClickAwayListener>
    //         </Paper>
    //       </Grow>
    //     )}
    //   </Popper>
    // </>
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
      />

      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {ShowFlag(langs[selectedIndex], false)}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 111
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom"
                  ? "center top"
                  : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {langs.map((lang, index) => (
                    <>
                      <MenuItem
                        key={lang.code}
                        selected={
                          index === selectedIndex
                        }
                        onClick={(event) =>
                          handleMenuItemClick(
                            event,
                            index
                          )
                        }
                      // disabled={index === 2}
                      >
                        {ShowFlag(lang, true)}
                      </MenuItem>
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
