//dependencies
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "@mui/material/Snackbar";

//components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

//image
import bannerImage from "../../assets/images/news.jpeg";
import emoji from "../../assets/images/Emojis-peace-smile.png";

//css
import "./contactUs.scss";

function ContactUs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [eMail, setEMail] = useState<string>("");
  const [telephone, setTelephone] = useState<any>();
  const [message, setMessage] = useState<string>("");
  const [isVerified, setIsVerified] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [locale, setLocale] = React.useState<any>(
    localStorage.getItem("i18nextLng")
  );

  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const localeData = localStorage.getItem("i18nextLng");

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  /**
   * useEffect
   */
  useEffect(() => {
    setLocale(localeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  /**
   * ContactUs Post Api
   * @param e
   */
  function contactUs(e: any) {
    e.preventDefault();
    setIsVerified(e ? true : false);
    let body = {
      data: {
        FirstName: firstName,
        LastName: lastName,
        Email: eMail,
        Telephone: telephone,
        Message: message,
        locale: locale,
      },
    };

    fetch(`${process.env.REACT_APP_API_URI}v1/contact-uses`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      setLoading(true);
      setOpenSnackbar(true);
      setTimeout(() => {
        if (res.status === 200) {
          setLoading(false);
          setFirstName("");
          setLastName("");
          setEMail("");
          setTelephone("");
          setMessage("");
          setIsVerified(false);
          setOpenSnackbar(false);
          navigate(`/${localeData}/thankYou`);
        } else {
        }
      }, 2000);
    });
  }

  return (
    <div>
      <Header />
      <div className="contactPage">
        <div
          className="headingBanner"
          style={{ backgroundImage: `url(${bannerImage})` }}
        >
          <div className="container">
            <div className="headingBanner__content">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  onClick={() => {
                    navigate(`/${localeData}`);
                  }}
                >
                  {t("breadcrumbs.home")}
                </Link>
                <Typography color="text.primary">
                  {t("header.contact")}
                </Typography>
              </Breadcrumbs>
              <h2 className="headingBanner__title">{t("header.contact")}</h2>
            </div>
          </div>
        </div>
        <div className="contactForm">
          <div className="container">
            <img className="emojiBg" src={emoji} alt="" />
            <div className="contactForm__heading">
              <h2 className="contactForm__title">{t("contact.getInTouch")}</h2>
              <p>{t("contact.intro")}</p>
            </div>
            <form onSubmit={contactUs}>
              <TextField
                id="outlined-basic"
                label={t("contact.firstName")}
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
              />
              <TextField
                id="outlined-basic"
                label={t("contact.lastName")}
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
              />
              <TextField
                id="outlined-basic"
                label={t("contact.e-mail")}
                variant="outlined"
                value={eMail}
                onChange={(e) => setEMail(e.currentTarget.value)}
                type="email"
              />
              <TextField
                id="outlined-basic"
                label={t("contact.telephone")}
                variant="outlined"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                type="number"
              />
              <TextField
                multiline
                rows={3}
                id="outlined-basic"
                label={t("contact.message")}
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
              />
              <div>
                <ReCAPTCHA sitekey="6LcP5mIlAAAAACRVk-Uy5O6KDqaDpXlzHgvS30rE" />
                {isVerified && <p>Verified!</p>}
              </div>
              <button
                type="submit"
                className="todo_button"
                disabled={
                  !firstName || !lastName || !eMail || !telephone || !message
                }
              >
                {t("contact.submit")}
              </button>
            </form>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openSnackbar}
              onClose={handleClose}
              message={t("contact.emailSentSuccessfully!")}
            />
            {/* <div className="CustomCheckBox">
              <Checkbox {...label} /> Please fill out this form and we will get back to you.
            </div> */}
          </div>
        </div>
        <div className="mediaContentBlock">
          <div className="container">
            <div className="mediaContentBlock__inner">
              <div className="mediaContentBlock__content">
                <h5 className="mediaContentBlock__title">
                  {t("contact.aboutHeading")}
                </h5>
                <p>{t("contact.aboutText")}</p>
                <h6 className="mediaContentBlock__subTitle">
                  {t("contact.aboutHeading2")}
                </h6>
                <p>{t("contact.aboutText2")}</p>
                <h6 className="mediaContentBlock__subTitle">
                  {t("contact.detailsHeading")}
                </h6>
                <p>
                  <div className="detailSection">
                    <div className="detailHeader">
                      {t("contact.s4PhoneAddress")}
                    </div>
                    <div className="detailValue">
                      <b>Sone 4 AS</b>
                      <br />
                      Burveien 39
                      <br />
                      2008 Fjerdingby
                    </div>
                  </div>
                  <div className="detailSection">
                    <div className="detailHeader">
                      {t("contact.s4PhoneHeading")}
                    </div>
                    <div className="detailValue">+47 404 08 628</div>
                  </div>
                  <div className="detailSection">
                    <div className="detailHeader">
                      {t("contact.s4PVATHeading")}
                    </div>
                    <div className="detailValue">814 035 662 MVA</div>
                  </div>
                </p>
              </div>
              <div className="mediaContentBlock__image">
                <img src={bannerImage} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="videoBlock">
          <div className="container">
            <div className="videoBlock__inner">
              <iframe
                width="640"
                height="360"
                src="https://www.youtube.com/embed/eg_B8ipRN3c?rel=0&amp;autoplay=1&controls=0&disablekb=0&showinfo=0"
                title="myFrame"
              ></iframe>
            </div>
          </div>
        </div> */}
        {/* <div className="mediaContentBlock mediaContentBlock--right">
          <div className="container">
            <div className="mediaContentBlock__inner">
              <div className="mediaContentBlock__content">
                <h5 className="mediaContentBlock__title">{t("contact.detailsHeading")}</h5>
                <table>
                  <tr>
                    <th>{t("contact.s4PhoneAddress")}</th>
                    <td><b>Sone 4 AS</b><br/>Burveien 39<br/>2008 Fjerdingby</td>
                  </tr>
                  <tr>
                    <th>{t("contact.s4PhoneHeading")}</th>
                    <td>+47 404 08 628</td>
                  </tr>
                  <tr>
                    <th>{t("contact.s4PVATHeading")}</th>
                    <td>814 035 662 MVA</td>
                  </tr>
                </table>
              </div>
              <div className="mediaContentBlock__image">
                <img src={bannerImage} className="img-fluid" alt="" />
              </div>
            </div>
          </div> 
        </div>*/}
      </div>
      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default ContactUs;
