/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  replaceUploadPath
} from "../../../src/components/Helper";

//components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Partner from "../../interface/interface";

//image
import bannerImage from "../../assets/images/Partners.jpeg";

const Partners = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [partnerData, setPartnersData] = React.useState<Array<Partner>>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [locale, setLocale] = React.useState<any>(
    localStorage.getItem("i18nextLng")
  );

  const localeData = localStorage.getItem("i18nextLng");

  /**
   * useEffect
   */
  useEffect(() => {
    getAllPartners();
    setLocale(localeData);
  }, [locale]);

  /**
   * getAllPartners Api
   */
  const getAllPartners = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}v1/partners?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setPartnersData(json.data);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div
        className="headingBanner"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="container">
          <div className="headingBanner__content">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => {
                  navigate(`/${localeData}`);
                }}
              >
                {t("breadcrumbs.home")}
              </Link>
              <Typography color="text.primary">
                {t("header.partners")}
              </Typography>
            </Breadcrumbs>
            <h2 className="headingBanner__title">{t("header.partners")}</h2>
          </div>
        </div>
      </div>
      <section className="serviceListBlock">
        <div className="container">
          <div className="row">
            {partnerData?.map((item: Partner) => {
              return (
                <div className="col-lg-6 col-md-12 mb-4">
                  <div className="newsCard mx-3 my-4" onClick={() => {
                    navigate(`/${localeData}/partners/${item.id}`, {
                      state: item?.attributes,
                    });
                  }}>
                    <div className="newsCard__img" >
                      <img
                        src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.[0].attributes?.url}`}
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="newsCard__content">
                      <h5 className="newsCard__title">
                        {item?.attributes?.title}
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            replaceUploadPath(item?.attributes?.descriptions),
                        }}
                      ></div>
                      <div
                        className="newsCard__btn"
                      >
                        {item?.attributes?.link}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      {isLoading && (
        <div className="loaderBox">
          <div className="loading-container">
            <div className="loading__blocks">
              <i className="line line--bottom"></i>
              <i className="line line--left"></i>
              <i className="line line--right"></i>
              <i className="line line--roof-left"></i>
              <i className="line line--roof-right"></i>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Partners;
