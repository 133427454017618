//dependencies
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function MetaData() {
    const location = useLocation();
    const path = location.pathname;
    const [keywords, setKeywords] = useState('Odatio, wms, warehouse management system, savoye, Supply Chain Execution Management');
    const [locale, setLocale] = React.useState<any>(localStorage.getItem("i18nextLng"));

    /**
     * Local Storage
     */
    const localeData = localStorage.getItem("i18nextLng")

    /**
   * useEffect
   */
    useEffect(() => {
        setLocale(localeData);
    }, [locale]);


    useEffect(() => {
        productData();
        newsData()
        partnersData()
    }, [path]);


    const productData = () => {
        fetch(`${process.env.REACT_APP_API_URI}v1/products?populate=*&locale=` + locale)
            .then((response) => response.json())
            .then((json) => {
                const products = json.data;
                // Extract the product ID from the path
                const productId = path.substring(path.lastIndexOf('/') + 1);
                // Find the matching product by ID
                const matchingProduct = products.find((product: any) => product.id === parseInt(productId));
                if (matchingProduct) {
                    setKeywords(matchingProduct.attributes.keyWords);
                } else {
                    // setKeywords('Sone 4 AS - ODATiO');
                }
            })
            .catch((error) => {
                console.error('Error fetching product data:', error);
            });
    };

    const newsData = () => {
        fetch(`${process.env.REACT_APP_API_URI}v1/homes?populate=*&locale=` + locale)
            .then((response) => response.json())
            .then((json) => {
                const news = json.data;
                // Extract the product ID from the path
                const newsId = path.substring(path.lastIndexOf('/') + 1);
                // Find the matching product by ID
                const matchingNews = news.find((news: any) => news.id === parseInt(newsId));
                if (matchingNews) {
                    setKeywords(matchingNews.attributes.keyWords);
                } else {
                    // setKeywords('Sone 4 AS - ODATiO');
                }
            })
            .catch((error) => {
                console.error('Error fetching product data:', error);
            });
    };


    const partnersData = () => {
        fetch(`${process.env.REACT_APP_API_URI}v1/partners?populate=*&locale=` + locale)
            .then((response) => response.json())
            .then((json) => {
                const partners = json.data;
                // Extract the product ID from the path
                const partnersId = path.substring(path.lastIndexOf('/') + 1);
                // Find the matching product by ID
                const matchingNews = partners.find((partners: any) => partners.id === parseInt(partnersId));
                if (matchingNews) {
                    setKeywords(matchingNews.attributes.keyWords);
                } else {
                    // setKeywords('Sone 4 AS - ODATiO');
                }
            })
            .catch((error) => {
                console.error('Error fetching product data:', error);
                setKeywords('Sone 4 AS - ODATiO');
            });
    };


    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content={keywords} />
            </Helmet>


        </div>
    )
}

export default MetaData
