//dependencies
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import moment from 'moment';
import {
    replaceUploadPath
} from "../../../src/components/Helper";

//components
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

//css
import "./news.scss";

function NewsDetails() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = React.useState(false);
    const [locale, setLocale] = React.useState<any>("nb-NO");
    const [newsData, setNewsData] = React.useState<any>([]);
    const [filterNews, setFilterNews] = React.useState<any>([]);

    const localeData = localStorage.getItem("i18nextLng");

    /**
 * useEffect
 */
    useEffect(() => {
        getAllNews();
        setLocale(localeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    useEffect(() => {
        setFilterNews(newsData);
    }, [newsData]);

    /**
 * getAllNews Api
 */
    const getAllNews = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URI}v1/homes/${id}?populate=*&locale=` + locale)
            .then((response) => response.json())
            .then((json) => {
                setNewsData(json?.data?.attributes);
                setLoading(false);
            });
    };

    const publishedDate = moment(filterNews.publishedAt);
    // const currentDate = moment(); // Current date
    const formattedDate = publishedDate.format('dddd, MMMM D YYYY');
    // const isToday = publishedDate.isSame(currentDate, 'day');

    return (
        <div>
            <Header />
            {filterNews?.headingImage?.data !== null && <div
                className="headingBanner"
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URI}${filterNews?.headingImage?.data?.[0].attributes?.url})`,
                }}
            >
                <div className="container">
                    <div className="headingBanner__content">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                color="inherit"
                                onClick={() => {
                                    navigate(`/${localeData}`);
                                }}
                            >
                                {t("breadcrumbs.home")}
                            </Link>

                            <Link
                                color="inherit"
                                onClick={() => {
                                    navigate(`/${localeData}/news`);
                                }}
                            >
                                {filterNews?.label}
                            </Link>
                            <Typography color="text.primary">
                                {filterNews?.title}
                            </Typography>
                        </Breadcrumbs>
                        <h2 className="headingBanner__title">
                            {" "}
                            {filterNews?.title}
                        </h2>

                    </div>
                </div>
            </div>}

            <div className="contentModule">
                <div className="container">
                    <div className="contentModule__inner">
                        <h3 className="contentModule__title">
                            {filterNews?.mainTitle}
                        </h3>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    replaceUploadPath(filterNews?.descriptions)
                            }}
                        ></div>
                        <h5 className="contentModule__subTitle">
                            {" "}
                            {filterNews?.subTitle}
                        </h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    replaceUploadPath(filterNews?.subDescription)
                            }}
                        ></div>

                        <div className="newsCard__date">
                            {formattedDate}
                        </div>

                        {filterNews?.subImage?.data !== null && (
                            <img
                                src={`${process.env.REACT_APP_API_URI}${filterNews?.subImage?.data?.[0].attributes?.url}`}
                                className="w-100 img-fluid"
                                alt=""
                            />
                        )}
                    </div>
                </div>
            </div>

            {filterNews?.image?.data !== null && <div className="videoBlock">
                <div className="container">
                    <div className="videoBlock__inner">
                        <img
                            src={`${process.env.REACT_APP_API_URI}${filterNews?.image?.data?.[0].attributes?.url}`}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>
            </div>}

            {isLoading && (
                <div className="loaderBox">
                    <div className="loading-container">
                        <div className="loading__blocks">
                            <i className="line line--bottom"></i>
                            <i className="line line--left"></i>
                            <i className="line line--right"></i>
                            <i className="line line--roof-left"></i>
                            <i className="line line--roof-right"></i>
                        </div>
                        <span>Loading...</span>
                    </div>
                </div>
            )}

            {filterNews?.videoLink?.data !== null && <div className="videoBlock" style={{ background: "#fff" }}>
                <div className="container">
                    <div className="videoBlock__inner" style={{ maxWidth: "100%" }}>
                        <ReactPlayer
                            url={`${process.env.REACT_APP_API_URI}${filterNews?.videoLink?.data?.[0].attributes?.url}`}
                            playing={true}
                            controls={true}
                            loop={true}
                            muted={true}
                            playsinline={true}
                        />
                    </div>
                </div>
            </div>}
            <Footer />
        </div>
    )
}

export default NewsDetails
