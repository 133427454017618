/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//components
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import News from "../../interface/interface";
import Products from "../../interface/interface";

//image
import bannerImage from "../../assets/images/Lines-Transparent.gif";
import background from "../../assets/images/Sone4-grafikk-02 (1).png";
//css
import "./home.scss";

/**
 * Modal style CSS
 */
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState<string>("");
  const [newsData, setNewsData] = React.useState<Array<News>>([]);
  const [product, setProduct] = React.useState<Array<Products>>([]);
  const [filterNews, setFilterNews] = React.useState<Array<News>>([]);
  const [temp, setTemp] = React.useState<any>(localStorage.getItem("password"));
  const [productDataFilter, setProductDataFilter] = React.useState<
    Array<Products>
  >([]);
  const [locale, setLocale] = React.useState<any>(localStorage.getItem("i18nextLng"));
  const localeData = localStorage.getItem("i18nextLng");


  /**
   * Product Sorting Data
   */
  const sortedData = product.sort((a, b) => a.id - b.id);


  /**
   * getAllNews Api
   */
  const getAllNews = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}v1/homes?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setNewsData(json.data)
        setLoading(false);
      });
  };

  /**
   * getAllProduct Api
   */
  const getAllProduct = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URI}v1/products?populate=*&locale=` + locale)
      .then((response) => response.json())
      .then((json) => {
        setProduct(json.data)
        setLoading(false);
      });
  };

  /**
   * useEffect
   */
  useEffect(() => {
    // setOpen(true);
    getAllNews();
    getAllProduct();
    setLocale(localeData);
  }, [locale]);

  /**
   * Get the Password in localStorage
   */

  useEffect(() => {
    if (temp === "") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setTemp(temp);
  }, [temp]);

  useEffect(() => {
    const sortedNews = newsData.sort((a, b) => new Date(b.attributes.publishedAt).getTime() - new Date(a.attributes.publishedAt).getTime());
    setFilterNews(sortedNews.slice(0, 3));
  }, [newsData]);

  useEffect(() => {
    setProductDataFilter(product);
  }, [product]);

  /**
   * handleSubmit
   */
  const handleSubmit = () => {
    setOpen(false);
    localStorage.setItem("password", password);
  };



  return (
    <>
      <div className="HomePage-lang">
        <div className="header-lang"></div>
        <Header />
      </div>
      <div className="HomePage">
        {!open === true && (
          <>
            <div className="heroBanner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="heroBanner__content">
                      <h2 className="heroBanner__title">
                        {t("home.SoftwareArchitectureProgrammingAndAutomation")}
                      </h2>
                      <div></div>
                      <div className="heroBanner__btnGroup">
                        {productDataFilter
                          .filter(
                            (item: Products) =>
                              item?.attributes?.frontPage === true
                          )
                          .map((item: Products) => {
                            return (
                              <>
                                {item?.attributes?.title && <button
                                  className="heroBanner__btn"
                                  onClick={() => {
                                    navigate(`/${localeData}/products/${item?.id}`, {
                                      state: item?.attributes,
                                    });
                                  }}
                                >
                                  {item?.attributes?.title}
                                </button>}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="heroBanner__img" style={{ backgroundImage: `url('${background}')` }}>
                      <img
                        src={bannerImage}
                        alt="newsImage"
                        className="img-fluid"
                      />
                      {/* <img
                        src={background}
                        alt="newsImage"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="newsBlock">
              <div className="container">
                <div className="newsBlock__heading">
                  <h6 className="newsBlock__subTitle">{t("home.newsFrom")}</h6>
                  <h5 className="newsBlock__title">
                    {t("home.areYouUpdated?")}
                  </h5>
                </div>

                <div className="row">
                  {/* start Map */}
                  {filterNews.map((item: News) => {
                    return (
                      <div className="col-lg-4" key={item.id}>
                        <div className="newsCard" onClick={() => {
                          navigate(`/${localeData}/news/${item.id}`, {
                            state: item?.attributes,
                          });
                        }}>
                          <div className="newsCard__img">
                            {item?.attributes?.image && <img
                              src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.[0].attributes?.url}`}
                              alt="newsImage"
                              className="img-fluid"
                            />}

                          </div>
                          <div className="newsCard__content">
                            <h5 className="newsCard__title">
                              {item?.attributes?.title}
                            </h5>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  item?.attributes?.descriptions,
                              }}
                            ></div>
                            <div className="newsCard__btn">
                              {item?.attributes?.link}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* end Map */}
                </div>
              </div>
            </div>
            <div className="contentMediaModule">
              {/* map Start */}
              {sortedData
                .filter(
                  (item: Products) =>
                    item?.attributes?.frontPage === true
                )
                .map((item: Products) => {
                  return (
                    <>
                      <div
                        key={item.id}
                        className="contentMediaBlock"
                        style={{ background: item?.attributes?.backgroundColor }}
                      >
                        <div className="container">
                          <div className="row align-items-center">
                            <div className="col-lg-6 contentMediaBlock__img">
                              {item?.attributes?.image && <img
                                src={`${process.env.REACT_APP_API_URI}${item?.attributes?.image?.data?.attributes?.url}`}
                                alt="newsImage"
                                className="img-fluid"
                              />}
                            </div>
                            <div className="col-lg-6 contentMediaBlock__text">
                              <h6 className="contentMediaBlock__subTitle">
                                {item?.attributes?.label}
                              </h6>
                              <h5 className="contentMediaBlock__title">
                                {item?.attributes?.title}
                              </h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.attributes?.descriptions,
                                }}
                              ></div>
                              {item?.attributes?.link && <button
                                className="contentMediaBlock__btn"
                                onClick={() => {
                                  navigate(`/${localeData}/products/${item.id}`, {
                                    state: item?.attributes,
                                  });
                                }}
                              >
                                {item?.attributes?.link}
                              </button>}

                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              {/* map end */}
            </div>
            {isLoading && (
              <div className="loaderBox">
                <div className="loading-container">
                  <div className="loading__blocks">
                    <i className="line line--bottom"></i>
                    <i className="line line--left"></i>
                    <i className="line line--right"></i>
                    <i className="line line--roof-left"></i>
                    <i className="line line--roof-right"></i>
                  </div>
                  <span>Loading...</span>
                </div>
              </div>
            )}
          </>
        )}

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="setPassModel"
        >
          <Box sx={style}>
            <ValidatorForm onSubmit={handleSubmit}>
              <TextValidator
                className="form-control"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(event.target.value)
                }
                type="password"
                name="password"
                value={password}
                id="standard-basic"
                label="Password"
                variant="outlined"
                validators={["required"]}
                errorMessages={["Please enter password"]}
              />
              <Box
                className="setPassModel__btn"
                display={"flex"}
                justifyContent={"flex-end"}
                marginTop={"20px"}
              >
                <Button type="submit">Submit</Button>
              </Box>
            </ValidatorForm>
          </Box>
        </Modal>
      </div>

      <Footer />
    </>
  );
};

export default Home;
