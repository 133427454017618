import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { i18nextPlugin } from 'translation-check'

//Good tort
//https://phrase.com/blog/posts/localizing-react-apps-with-i18next/

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(HttpApi)
  .use(i18nextPlugin)
  //  Enables analysing transalations using the ?showtranslations param
  //  https://github.com/locize/translation-check
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: 'nb-NO',
    // lng: "nb-NO",
    saveMissing: true, // send not translated keys to endpoint
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }    
  });

export default i18n;
