/* eslint-disable react-hooks/exhaustive-deps */
//dependencies
import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

//components
import LanguageSelect from "../Language/LanguageSelect";
import SoneBrand from "../../assets/images/Sone4-logo.svg";

//css
import "../Header/Header.scss";

function Header() {
  const { t } = useTranslation();
  const [toggle, setToggle] = React.useState<boolean>(true);
  const [locale, setLocale] = React.useState<any>("nb-NO");

  const localeData = localStorage.getItem("i18nextLng")

  /**
 * useEffect
 */
  useEffect(() => {
    setLocale(localeData);
  }, [locale]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <div className="siteHeader">
          <div className="top-navbar mobile-topbar">
            <LanguageSelect />
          </div>
          <AppBar className="custom-header" position="static">
            <Toolbar
              className={
                toggle
                  ? "showMobileMenu siteHeader__inner"
                  : "siteHeader__inner"
              }
            >
              <a className="header-logo" href={`/${localeData}`}>
                <img src={SoneBrand} alt="" />
              </a>
              <div className="siteHeader__menuBlock">
                <div className="top-navbar desktop-topbar">
                  <LanguageSelect />
                </div>
                <IconButton
                  className={
                    !toggle
                      ? "showMobileMenu mobileToggleMenu"
                      : "mobileToggleMenu"
                  }
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setToggle(!toggle)}
                >
                  <div className="showMobileMenubtn">
                    <MenuIcon />
                  </div>
                </IconButton>
                <div className="navbar-right">
                  <div className="desktopMenu">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link activeMenu" : "nav-link"
                      }
                      to={`/${localeData}/products`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("header.productAndService")}
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link activeMenu" : "nav-link"
                      }
                      color="inherit"
                      to={`/${localeData}/news`}
                    >
                      {t("header.news")}
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link activeMenu" : "nav-link"
                      }
                      to={`/${localeData}/partners`}
                      color="inherit"
                    >
                      {t("header.partners")}
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link activeMenu" : "nav-link"
                      }
                      color="inherit"
                      to={`/${localeData}/contact-us`}
                    >
                      {t("header.contact")}
                    </NavLink>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </Box>
    </>
  );
}
export default Header;
